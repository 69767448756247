import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, {Suspense, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './main.less';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
// HashRouter BrowserRouter
import {App as AntdApp} from 'antd';

import {Button, ConfigProvider} from 'antd';
// import zhCN from 'antd/es/locale/zh_CN';
import {Provider} from 'react-redux'
import store from "./utils/store";

import NProgress from "./components/nprogress";
import common from "./utils/common";
import {Helmet} from "react-helmet";

// 由于 antd 组件的默认文案是英文，修改为中文
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';
import Passport from "./pages/passport";
import {CloseOutlined, CloseSquareOutlined} from "@ant-design/icons";
import PopupLogin from "./components/popuplogin";

// import Interactive from "./pages/interactive";
// import Answer from "./pages/answer";
// import Ide from "./pages/ide";
// import App from './App';
// import Screen from "./pages/screen"
// import Share from "./pages/share";
// import Exam from "./pages/exam";
// import ExamDetail from "./pages/exam/detail";
// import PPT from "./pages/ppt";
// import Demo from "./pages/demo";
// import Share2 from "./pages/share/index2";

// const Lesson = React.lazy(() => import("./pages/lesson"));
// const Run = React.lazy(() => import("./pages/lesson/run"));
// const LessonV2 = React.lazy(() => import("./pages/lesson/v2"));
// const Ide = React.lazy(() => import("./pages/ide"));
// const Answer = React.lazy(() => import("./pages/answer"));
const App = React.lazy(() => import("./App"));
const Screen = React.lazy(() => import("./pages/screen"));
const Share = React.lazy(() => import("./pages/share"));
const ShareTeacher = React.lazy(() => import("./pages/share/teacher"));
const ShareStudent = React.lazy(() => import("./pages/share/student"));
const Exam = React.lazy(() => import("./pages/exam"));
const ExamDetail = React.lazy(() => import("./pages/exam/detail"));
const PPT = React.lazy(() => import("./pages/ppt"));
const Demo = React.lazy(() => import("./pages/demo"));
const LoginResult = React.lazy(() => import("./pages/loginResult"));
const H5Card = React.lazy(() => import("./pages/h5/card"));
const Type = React.lazy(() => import("./pages/type"));
const Player = React.lazy(() => import("./pages/player"));
const Attendance = React.lazy(() => import("./pages/attendance"));
const LearnPath = React.lazy(() => import("./pages/learningPath"));
const LearnPathRoot = React.lazy(() => import("./pages/learningPath/root")); // 根人才计划
const Home = React.lazy(() => import("./pages/home"));
const Discover = React.lazy(() => import("./pages/discover"));
const Ym = React.lazy(() => import("./pages/ym"));
const RegisterV3 = React.lazy(() => import("./pages/passport/RegisterV3"));
const PadHome = React.lazy(() => import("./pages/k12/home"));
const PadLogin = React.lazy(() => import("./pages/k12/login"));
const PadRegister = React.lazy(() => import("./pages/k12/register"));

dayjs.locale('zh-cn');


const LessonV2 = (props) => {
    let sectionId = props.match.params.sectionId                     //  当前节id
    window.location.href = "/playground/#/lesson/" + sectionId
    return (<>
        <Button type={'link'} onClick={() => {
            window.history.go(-1)
        }}>返回</Button>
    </>)
}

const RunCode = (props) => {
    let lang = props.match.params.lang
    window.location.href = "/playground/#/run/" + lang
    return (<></>)
}

function Wrap(props) {

    // const { message, notification, modal } = AntdApp.useApp();
    const antdApp = AntdApp.useApp();

    common.setAntdApp(antdApp)

    const removeSsr = () => {
        // 删除 SSR 部份
        if (window.document.getElementById('root').innerHTML.trim().length !== 0) {

            // React渲染完成后，50ms，是为了等ajax取数据
            //setTimeout(() => {
            window.document.getElementById('ssr').remove()
            window.document.getElementById('ssrMask').remove()
            // }, 50)

            return
        }
        setTimeout(removeSsr, 50)
    }

    useEffect(() => {
        removeSsr()
    }, [])
    return <>{props.children}</>
}

function Main(props) {

    const [load, setLoad] = useState(true)
    const [tenant, setTenant] = useState({})
    const [loginPopup, setLoginPopup] = useState(false)

    useEffect(() => {
        setLoad(true)
        //common.loadingStart()  // 此时还没有获取到主题颜色，不做loading
        common.ajax('get', '/api/tenant/info', {
            domain: window.location.hostname
            // domain: 'www.daimaku.net'
            // domain: 'ydm.daimaku.net'
            // domain: 'ke.itxb.net'
        }, {
            useToken: false,
        }).then((res) => {
            if (res.theme) {
                res.theme = JSON.parse(res.theme)
            }
            setTenant(res)
            store.dispatch({type: 'CHANGE_TENANT', tenant: res})
        }).finally(() => {
            setLoad(false)
            //common.loadingStop()
        })

    }, [window.location.hostname])


    // 获取未读消息数量  后端依赖这个api统计用户在线时长
    const fetchMessage = () => {
        common.ajax("get", "/api/message/unread", {}, {
            interceptInvalidToken: false,
            displayError: false,
            timeout: 5000,
        }).then(res => {
            store.dispatch({type: 'CHANGE_UNREAD', payload: res.unread_num})
        })
    }

    function isInIframe() {
        return window.self !== window.top
    }

    function checkZoomLevel() {
        // 获取缩放比例的不同方法
        const ratio = Math.round((window.outerWidth / window.innerWidth) * 100);

        // 如果缩放比例不是100%，则显示提示信息
        if (Math.abs(ratio - 100) > 10) {

            // 不在 iframe 中，才提示。Ai聊天框在iframe中运行。
            if (!isInIframe()) {
                common.alert(`您的浏览器显示比例不是100%，建议您将显示比例恢复为100%，以获得最佳显示效果。`);
            }
        }
    }

    useEffect(() => {

        store.subscribe(() => {
            setLoginPopup(store.getState().nav.loginPopup)
        });

        // setTimeout(() => {
        //     checkZoomLevel()
        // }, 1000)

        fetchMessage()

        let timer = setInterval(() => {
            fetchMessage()
        }, 60 * 2 * 1000)

        return () => {
            clearInterval(timer)
        }
    }, [])


    return <ConfigProvider locale={zhCN}
                           theme={{
                               cssVar: {key: 'app'},  // 开启 CSS 变量模式，在 React 17 或者 16 中，你需要手动为每一个主题设置一个唯一的 key，否则会导致主题混乱。开启后审查元素，就可以看到 antd 组件样式中一些原本具体的数值被替换为了 CSS 变量：
                               token: {
                                   // Seed Token，影响范围大
                                   // colorPrimary: '#00b96b',
                                   colorLink: tenant?.theme?.colorPrimary ?? '#1677ff',
                                   colorPrimary: tenant?.theme?.colorPrimary ?? '#1677ff',
                                   colorInfo: tenant?.theme?.colorPrimary ?? '#1677ff',
                                   fontFamily: '"Helvetica Neue",Helvetica,Arial,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","WenQuanYi Micro Hei","Heiti SC",sans-serif',
                                   // borderRadius: 2,
                                   // algorithm: [theme.compactAlgorithm],
                                   // 派生变量，影响范围小
                                   // colorBgContainer: '#f6ffed',
                               },
                           }}

    >
        <Provider store={store}>
            <AntdApp className={'my-antd-app'}>
                {load ? "" :
                    <Wrap>
                        {tenant.name ?
                            <Helmet>
                                <title>{tenant.name}</title>
                            </Helmet> : ''}
                        <Router>
                            <Suspense fallback={<NProgress></NProgress>}>
                                <Switch>
                                    <Route exact path={'/k12/pad'} component={PadHome}></Route>
                                    <Route path={'/k12/login'} component={PadLogin}></Route>
                                    <Route path={'/k12/register'} component={PadRegister}></Route>
                                    <Route path={'/attendance/:id'} component={Attendance}/>
                                    <Route path={'/exam/:id'} component={Exam}/>
                                    <Route path={'/examDetail/:id'} component={ExamDetail}/>
                                    <Route exact path={'/run/:lang'} component={RunCode}></Route>
                                    {/*<Route exact path={'/lesson/:sectionId'} component={Lesson}></Route>*/}
                                    <Route exact path={'/lesson/:sectionId'} component={LessonV2}></Route>
                                    {/*<Route exact path={'/lesson/:sectionId'} component={LessonV2}></Route>*/}
                                    {/*<Route exact path={'/ide'} component={Ide}></Route>*/}
                                    {/*<Route exact path={'/answer'} component={Answer}></Route>*/}
                                    <Route exact path={'/screen'} component={Screen}></Route>
                                    <Route exact path={'/share'} component={Share}></Route>
                                    <Route exact path={'/share/teacher'} component={ShareTeacher}></Route>
                                    <Route exact path={'/share/student/:roomId'} component={ShareStudent}></Route>
                                    {/*<Route exact path={'/share'} component={Share2}></Route>*/}
                                    <Route exact path={'/ppt'} component={PPT}></Route>
                                    <Route exact path={'/typing'} component={Type}></Route>
                                    <Route exact path={'/demo'} component={Demo}></Route>
                                    <Route exact path={'/h5/card'} component={H5Card}></Route>
                                    <Route exact path={'/loginResult/:result'} component={LoginResult}></Route>
                                    <Route exact path={'/player'} component={Player}></Route>
                                    <Route exact path={'/learning-path'} component={LearnPath}></Route>
                                    <Route exact path={'/learning-path-root'} component={LearnPathRoot}></Route>
                                    <Route exact path={'/discover'} component={Discover}></Route>
                                    <Route exact path={'/ym'} component={Ym}></Route>
                                    <Route exact path={'/register'} component={RegisterV3}></Route>
                                    {/*<Route exact path={'/'} component={Home}></Route>*/}
                                    <Route path={'/'} component={App}></Route>
                                    <Route path="*">
                                        <div>404 Not Found</div>
                                    </Route>
                                </Switch>
                            </Suspense>

                            {loginPopup ?
                                <>
                                    <PopupLogin onClose={() => {
                                        store.dispatch({type: 'CHANGE_LOGIN_POPUP', loginPopup: false})
                                    }}></PopupLogin>

                                    {/*<div className={"LoginLayer"}>*/}
                                    {/*<span title={"暂不登录"}*/}
                                    {/*      style={{fontSize: 20, color: '#fff', margin: 10, cursor: "pointer"}}*/}
                                    {/*      onClick={() => {*/}
                                    {/*          store.dispatch({type: 'CHANGE_LOGIN_POPUP', loginPopup: false})*/}

                                    {/*      }}><CloseSquareOutlined/></span>*/}
                                    {/*    <Passport props={props} onClose={() => {*/}

                                    {/*    }}></Passport>*/}
                                    {/*</div>*/}

                                </>
                                : ''}

                        </Router>
                    </Wrap>}
            </AntdApp>
        </Provider>
    </ConfigProvider>
}

export default Main
