import axios from 'axios';
import qs from 'qs';
// import {createHashHistory} from 'history';
import store from "./store";
// import {Modal, message} from 'antd';
import {baseApi, artifactId} from "../config-local"
import Cookies from 'js-cookie'
import md5 from 'js-md5'
import {v4 as uuidv4} from 'uuid';

let tokenKey = `${artifactId}-token`

let common = {}

common.setAntdApp = function (app) {
    common.antdApp = app
}

common.isDaimaku = function (tenant) {
    if (tenant.id == 1001) {
        return true;
    }
    return false
    // return window.location.host.indexOf('www.daimaku.net') !== -1 || window.location.hostname === 'localhost'
}

// 是否启用微信扫码登录
common.isEnableWechatLogin = function (tenant) {
    if (tenant.id == 1001 || tenant.id == 1004) {
        return true;
    }
    return false
}


// 生成完整的url
common.getFullUrl = function (url) {
    // let origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    // return origin + window.location.pathname + '#' + url
    let origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    return origin + url
}

common._loginTimer = null
common.redirectToLogin = function (popup) {

    if (popup) {
        store.dispatch({type: 'CHANGE_LOGIN_POPUP', loginPopup: true})
        return
    }


    // 如果一个页面调用了多个ajax，均返回需要登录，这个redirectToLogin就有可能被执行多次，所以做下延时，只执行一次跳转
    window.sessionStorage.setItem(`${artifactId}-returnUrl`, window.location.href)

    clearTimeout(common._loginTimer)

    common._loginTimer = setTimeout(() => {

        if (!common.isWechat()) {

            window.location = common.getFullUrl('/login')

        } else {

            window.popup.confirm("是否使用微信一键登录?", () => {
                window.location = 'https://www.it266.com/api/wechat/openid?redirectUrl=' + encodeURIComponent(common.getFullUrl('/loginResult/'))
            }, function () {
                window.location = common.getFullUrl('/login')
            });
        }
    }, 100)
}

common.getReturnUrl = function (defaultUrl) {
    let url = window.sessionStorage.getItem(`${artifactId}-returnUrl`)
    if (url) {
        window.sessionStorage.removeItem(`${artifactId}-returnUrl`)
        return url
    }
    return defaultUrl
}

common.setToken = function (token) {
    if (token) {
        window.localStorage.setItem(tokenKey, token)

        // 这个项目从cookie中获取token http://booklet.daimaku.net/
        // oj 也从cookie中获取token
        Cookies.set('token', token, {
            expires: 300, // 300 天，时间尽量长一点，避免cookie在token失效前过期。     chrome对cookie的最大有效期做了限制(400)天
            path: '/',
            domain: '.daimaku.net',
            secure: false,
        })

    } else {
        window.localStorage.removeItem(tokenKey)

        Cookies.remove('token', {path: '/', domain: '.daimaku.net'})
    }
}

common.getToken = function () {
    let token = window.localStorage.getItem(tokenKey)
    return token == null ? '' : token
}


/*
nickname: "",
avatar: "",
tenant: {name: ""},
company: {id: "", name: ""},
brand: {id: "", name: ""},
*/
common.getUser = function () {
    // return store.getState().user
}

common.getBaseApiUrl = function () {
    return baseApi
}

common.getApiUrl = function (api, param = {}) {

    if (api.startsWith('http')) {
        return api
    }

    let queryArr = []
    for (let k in param) {
        queryArr.push(encodeURIComponent(k) + '=' + encodeURIComponent(param[k]))
    }

    let queryStr = ''
    if (queryArr.length > 0) {
        const mark = api.indexOf('?') >= 0 ? '&' : '?'
        queryStr = mark + queryArr.join('&')
    }

    return common.getBaseApiUrl() + api + queryStr
}

common.ajax = function (method, api, data, config = {}) {

    data = data || {}

    const isGet = method.toLowerCase() === 'get'

    const configDefault = {
        'contentType': 'application/x-www-form-urlencoded', // application/x-www-form-urlencoded、multipart/form-data、application/json
        'timeout': 20000,                  // 调用api超时时间为20秒
        'displayError': true,              // 调用api出错时，是否显示错误消息
        'useToken': true,                  // api是否需要使用token。如果需要token而本地没有token时，将重定向到登录页
        'interceptInvalidToken': true,     // api返回token无效时，是否拦截。如果拦截，将重定向到登录页
    }

    config = Object.assign(configDefault, config)

    let headers = {
        'Content-Type': config['contentType']
    }

    if (config.useToken) {

        // data.token = common.getToken()
        headers['token'] = common.getToken()
        // if (headers['token'].length === 0) {
        //
        //     common.toast("请登录")
        //
        //     common.redirectToLogin()
        //
        //     return new Promise((resolve, reject) => {
        //         reject({code: 'MISS_TOKEN', data: "请登录"})
        //     })
        // }
    }

    if (!isGet && config['contentType'].toLowerCase() === 'application/x-www-form-urlencoded') {
        data = qs.stringify(isGet ? null : data)
    }

    return new Promise((resolve, reject) => {

        axios({
            method: method,
            url: common.getApiUrl(api, isGet ? data : {}),
            data: data,
            headers: headers,
            timeout: config.timeout
        }).then((response) => {

            if (response.data.status === true || response.data.code === 'SUCCESS') {
                resolve(response.data.data)
                return
            }

            switch (response.data.code) {
                case 'INVALID_TOKEN':
                    if (config.interceptInvalidToken) {

                        common.setToken(null)

                        if (config.displayError) {
                            // common.toast('请登录')
                            console.log('请登录')
                        }

                        // ajax 不是 GET，则弹窗登录，否则跳转登录。跳转回来，会重新拉取数据。如果用部用弹窗登录，不好处理登录成功后的数据拉取。
                        // 不是GET，说明在提交数据，自动跳转到登录页，容易导正在编辑还未保存的致数据丢掉了
                        common.redirectToLogin(method.toUpperCase() !== 'GET')
                    }
                    break

                default:
                    if (config.displayError) {
                        common.toast(response.data?.message ?? response.data.data)
                    }
            }

            reject(response.data)

        }).catch((error) => {
            config.displayError && common.toast("" + error)
            reject({code: 'ERROR', message: '' + error, data: null})
        })

    })
}


common.base64Encode = function (input) {
    let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4, i = 0, output = '', utftext = '';
    input = input.replace(/\r\n/g, "\n");
    for (let n = 0; n < input.length; n++) {
        let c = input.charCodeAt(n);
        if (c < 128) {
            utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }

    }
    while (i < utftext.length) {
        chr1 = utftext.charCodeAt(i++);
        chr2 = utftext.charCodeAt(i++);
        chr3 = utftext.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
}

common.isWechat = function () {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') >= 0;
}


common.alert = function (content, callback, title) {
    title = title || '提示'
    callback = callback || function () {
    }

    common.antdApp.modal.info({
        title: title,
        content: content,
        onOk() {
            callback()
        },
    });

    // window.popup.alert(content, function () {
    //     callback()
    // });

}

common.message = {
    success: (content, duration, onClose) => {
        common.antdApp.message.success(content, duration, onClose)
    },
    error: (content, duration, onClose) => {
        common.antdApp.message.error(content, duration, onClose)
    },
}

// 尽量用 common.message 可以区分是成功还是失败, toast 使用 info 图标，用户不方便感知是成功还是失败
common.toast = function (content, duration, onClose) {
    // duration = duration || 2 // 单位：秒
    //
    // onClose = onClose || function () {
    // }
    //
    common.antdApp.message.info(content, duration, onClose);

    // const modal = Modal.success({
    //     // title: 'This is a notification message',
    //     content: content,
    // });
    //
    // setTimeout(() => {
    //     modal.destroy();
    //     callback()
    // }, duration * 1000);

    // window.popup.cute(content, duration * 1000, callback);
}

common.loadingStart = function (message) {
    message = message || '加载中...'

    // window.popup.loading(true);

    let mask = document.createElement("div")
    mask.setAttribute("id", "myloadingmask")
    mask.style.position = "absolute"
    mask.style.width = "100%"
    mask.style.height = "100%"
    mask.style.zIndex = 999
    mask.style.top = "0px"
    mask.style.left = "0px"

    let wrap = document.querySelector(".ant-app")
    if (wrap == null) {
        wrap = document.getElementsByTagName("body")[0]
    }

    wrap.appendChild(mask)

    let container = document.createElement("div")
    container.setAttribute("id", "myloading")
    container.setAttribute("class", "container")

    let loading = document.createElement("div")
    loading.setAttribute("class", "loading")
    container.appendChild(loading)

    let style = document.createElement("style")
    style.innerHTML = `
     .container {
        z-index: 10000;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .loading {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        border: 5px #aaa solid;
        /*border-right-color: #1890ff;*/
        border-right-color: var(--ant-color-primary, #1890ff);
        animation: loading 1s linear infinite;
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }`

    container.appendChild(style)

    wrap.appendChild(container)
    // document.getElementsByTagName("body")[0].appendChild(container)

}

common.loadingStop = function () {
    // window.popup.loading(false);

    let wrap = document.querySelector(".ant-app")
    if (wrap == null) {
        wrap = document.getElementsByTagName("body")[0]
    }

    let myloading = document.getElementById("myloading")
    if (myloading) {
        // document.getElementsByTagName("body")[0].removeChild(myloading)
        wrap.removeChild(myloading)
    }

    let myloadingmask = document.getElementById("myloadingmask")
    if (myloadingmask) {
        // document.getElementsByTagName("body")[0].removeChild(myloadingmask)
        wrap.removeChild(myloadingmask)
    }
}

common.confirm = function (message, okCallback, cancelCallback, title, okText = '确定') {
    title = title || '提示'

    okCallback = okCallback || function () {
    }
    cancelCallback = cancelCallback || function () {
    }

    common.antdApp.modal.confirm({
        title: title,
        content: message,
        okText,
        onOk() {
            okCallback()
        },
        onCancel() {
            cancelCallback()
        },
    });

    // window.popup.confirm(message, okCallback, cancelCallback);
}

common.isMobile = function () {
    // 超小屏幕（手机，小于 768px）
    return window.screen.width < 768
}

common.randomString = (len) => {
    var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678oOLl9gqVvUuI1';
    var maxPos = chars.length;
    var pwd = '';
    for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

common.sign = function (str) {
    // return md5(str + new Date().getDay()) // 返回值为0-6，分别代表星期日到星期六。  不同时区，日期不相同，得到的星期几也不相同，不能加星期几
    return md5(str)
}

common.md5 = function (str) {
    return md5(str)
}

common.uuid = function () {
    return uuidv4()
}

common.deviceCode = () => {
    let deviceCode = window.localStorage.getItem('device_code')
    if (deviceCode == null) {
        deviceCode = common.uuid()
        window.localStorage.setItem('device_code', deviceCode)
    }
    return deviceCode
}

// 获取设备信息
common.deviceInfo = function () {
    return {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        screen: `${window.screen.width}x${window.screen.height}`
    };
}

export default common

